<template>
  <b-row style="background-color: #F5F7FA; padding-bottom: 50px;" class="d-flex justify-content-center content">
    <b-col style="max-width: 1200px; min-height: 800px;">
      <b-row>

        <b-col lg="8" offset-lg="2">

          <h1 v-if="event.event_id" style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">{{
            staticDisplayName }}</h1>
          <h1 v-else style="font-size: 20px; margin-left: 5px; margin-bottom: 15px; font-weight: 600;">New Event</h1>
          <b-card style="box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);" no-body>
            <b-card-body>
              <b-form @submit.prevent="saveEvent" @reset.prevent="onReset">

                <!-- ACTIVE TOGGLE -->
                <toggle-button @change="toggleActive" :value="event.active" :sync="true" :color="{
                  checked: '#28a745',
                  unchecked: '#6c757d',
                  disabled: '#343a40'
                }" :labels="{ checked: 'Active', unchecked: 'Inactive' }" :width="85" :height="30" :font-size="12"
                  class="float-right" />

                <!-- EVENT NAME -->
                <b-form-group id="name-group" label="Event Name:" label-for="name" description="">
                  <p v-show="!validation.name" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter an event name longer than 5 characters.
                  </p>
                  <b-form-input id="display-name" :class="{ 'error' : !validation.name}" style="max-width: 450px;"
                    v-model="event.name" placeholder="" trim></b-form-input>
                </b-form-group>

                <!-- TYPE -->
                <b-form-group id="type-group" label="Type:" label-for="type" description="">
                  <p v-show="!validation.type" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please make a selection.
                  </p>

                  <b-form-select :options="eventTypes" id="type" :class="{ 'error' : !validation.type }"
                    style="max-width: 450px;" v-model="event.type" trim>
                  </b-form-select>
                </b-form-group>

                <!-- EVENT IMAGE UPLOAD -->
                <label for="eventImage">Event Image:</label>
                <p class="small">(1164px x 140px | jpg)</p>
                <div style="max-width: 450px; margin-left:0px;" class="text-center img-thumbnail">
                  <b-img fluid v-if="event.image_url" :src="filePath + event.image_url" class="p-1 mb-2" />
                  <b-img fluid v-else :src="imagePlaceholder" class="p-1 mb-2" />
                  <b-form-file hidden v-model="newImage" ref="imageSelector" class="" plain></b-form-file>
                  <button @click.prevent="simulateImageSelectClick"
                    v-show="event.event_id && event.image_url && !processingImageUpload" id=""
                    class="btn btn-light btn-block" style="margin-top: 0px;">Replace Event Image</button>
                  <button @click.prevent="simulateImageSelectClick"
                    v-show="event.event_id && !event.image_url && !processingImageUpload" id=""
                    class="btn btn-light btn-block" style="margin-top: 0px;">Upload Event Image</button>
                  <button disabled v-show="processingImageUpload" id="" class="btn btn-light btn-block"
                    style="margin-top: 0px;">
                    <b-spinner class="mr-2" small></b-spinner> Uploading...
                  </button>
                  <p v-show="!event.event_id" class="small mb-1">Please save this event before adding an image.</p>
                </div>

                <!-- DESCRIPTION -->
                <b-form-group style="max-width: 450px;" class="mt-3" id="description-group" label="Description:"
                  label-for="description" description="">
                  <p v-show="!validation.description" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please enter an event description longer than 10 characters.
                  </p>
                  <b-form-textarea id="description" :class="{ 'error' : !validation.description }"
                    v-model="event.description" placeholder="" trim></b-form-textarea>
                </b-form-group>

                <!-- DATE -->
                <b-form-group id="date" label="Date:" label-for="date" description="">
                  <p v-show="!validation.date" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please select a date.
                  </p>
                  <b-form-datepicker id="date" v-model="event.date" style="max-width: 450px;" class="mb-2">
                  </b-form-datepicker>
                </b-form-group>

                <!-- TIME -->
                <b-form-group id="time" label="Time:" label-for="time" description="">
                  <p v-show="!validation.time" class="small" style="color: #d9534f; margin-bottom: 5px;">
                    Please add a valid time.
                  </p>
                  <b-form-input id="registration_btn_text" :class="{ 'error': !validation.time }"
                    style="max-width: 450px;" v-model="event.time" placeholder="" trim></b-form-input>
                </b-form-group>

                <!-- REGISTRATION BUTTON TEXT -->
                <b-form-group id="registration_btn_text" label="Registration Button Text:"
                  label-for="registration_btn_text" description="">
                  <p v-show="!validation.registration_btn_text" class="small"
                    style="color: #d9534f; margin-bottom: 5px;">
                    Please add something.
                  </p>
                  <b-form-input id="registration_btn_text" :class="{ 'error': !validation.registration_btn_text }"
                    style="max-width: 450px;" v-model="event.registration_btn_text" placeholder="" trim></b-form-input>
                </b-form-group>

                <!-- REGISTRATION BUTTON URL -->
                <b-form-group id="registration_btn_url" label="Registration Button URL:"
                  label-for="registration_btn_url" description="">
                  <p v-show="!validation.registration_btn_url" class="small"
                    style="color: #d9534f; margin-bottom: 5px;">
                    Please add something.
                  </p>
                  <b-form-input id="registration_btn_url" :class="{ 'error': !validation.registration_btn_url }"
                    style="max-width: 450px;" v-model="event.registration_btn_url" placeholder="" trim></b-form-input>
                </b-form-group>

                <!-- CATEGORY CHECKBOXES TO ASSOCIATE THIS EVENT WITH -->
                <b-form-group label="Related Categories:">
                  <b-form-checkbox-group class="pt-2" id="categories" name="categories" v-model="selectedCategories">
                    <b-form-checkbox v-for="category in allCategories" :key="category.category_id"
                      :value="category.category_id" class="pl-4 pr-4 pb-3" style="width: 220px;">{{
                      category.display_name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </b-form-group>

                <b-button v-show="!processing" type="submit" class="btn btn-primary btn-dash-submit float-right">Save
                </b-button>
                <b-button v-show="processing" disabled type="submit"
                  class="btn btn-primary btn-dash-submit float-right">
                  <b-spinner class="mr-2" small></b-spinner> Saving...
                </b-button>
                <b-button @click="cancel" class="btn btn-light float-right mr-3">Cancel</b-button>

              </b-form>

            </b-card-body>
          </b-card>

        </b-col>
      </b-row>
    </b-col>
  </b-row>

</template>

<script>

import { ToggleButton } from 'vue-js-toggle-button'

export default {
  name: 'edit-event',
  components: {
    ToggleButton,
  },

  created () {
    if(this.$route.params.eventId){
      this.getEvent();
    }
    this.getCategories();
  },

  watch: {
    'newImage'() {
      this.saveImage();
    },
  },

  data() {
    return {
      processing: false,
      processingImageUpload: false,
      imagePlaceholder: '/event_image_placeholder.jpg',
      filePath: process.env.VUE_APP_FILES_PATH,
      eventTypes: [
        { value: null, text: 'Please select an option', disabled: true },
        { value: 'webinar', text: 'Webinar' },
        { value: 'training', text: 'Training' },
        { value: 'conference', text: 'Conference' },
        { value: 'product_demo', text: 'Product Demo' },
      ],
      event: {
        active: true,
        name: '',
        type: '',
        description: '',
        date: '',
        time: '',
        image_url: '',
        registration_btn_text: '',
        registration_btn_url: '',
        categories: '',
      },
      selectedCategories: [],
      newImage: [],
      allCategories: {},
      validation: {
        name: true,
        type: true,
        description: true,
        date: true,
        time: true,
        registration_btn_text: true,
        registration_btn_url: true,
        categories: true,
      }
    }
  },

  metaInfo() {
    return {
      title: this.event.display_name ? this.event.display_name : 'New Event'
    }
  },

  methods: {

    getEvent(){
      this.$store.commit('generateApiCreds');

      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'events/id/' + this.$route.params.eventId, {       
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.event = resp.data;
          this.staticDisplayName = this.event.display_name;
          this.event.active = this.event.active == 1 ? true : false;

          // make a seperate array for vendor checkboxes if they exist
          if (this.event.categories) {
            this.selectedCategories = resp.data.categories.split(",");
          }

        })
        // .catch(err => {
        //   console.log(err);
        //   self.$router.push('/*');
        // });
    },

    saveEvent() {
      this.$store.commit("generateApiCreds");
      let self = this;

      if(!this.validateForm()){
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
        return false;
      }

      this.processing = true;

      const formData = new FormData();
      
      // Is this an existing event? If so we need to set our id.
      if(this.event.event_id){
        formData.append('event_id', this.event.event_id);
      }

      formData.append('user_id', this.$store.state.auth.userId);
      formData.append('token', this.$store.state.auth.token);

      // Set everything else except selected categories.
      for (let [key, value] of Object.entries(this.event)) {
        formData.append(key, value);
      }

      // now we can set our related categories, if there are any
      formData.delete('categories');
      formData.append('categories', this.selectedCategories.join(','));

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "events", formData, {
            auth: {
              username: this.$store.state.auth.apiUsername,
              password: this.$store.state.auth.apiPassword
            },
          })
        .then(function() {
          self.processing = false;
          self.$router.go(-1);
        })
        .catch(function() {
          alert('We have an error on our site. Please contact support and let us know. Thanks!')
          // console.log(resp);
          alert('error');
        });
    },

    saveImage() {
      this.processingImageUpload = true;
      this.$store.commit("generateApiCreds");

      let formData = new FormData();

      formData.append('upload_type', 'event_image');
      formData.append('name', this.event.name);
      formData.append('event_id', this.event.event_id);
      formData.append('file', this.newImage);

      const self = this;

      this.$axios
        .post(process.env.VUE_APP_API_PATH + "uploads", formData, {
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          },
        })
        .then(function ()
        {
          self.getEvent();
          self.processingImageUpload = false;
        })
        .catch(function ()
        {
          self.processingImageUpload = false;
          alert('We have an error on our site. Please contact support and let us know. Thanks!')
        });
    },

    simulateImageSelectClick() {
      this.$refs.imageSelector.$el.click();
    },

    getCategories() {
      this.$store.commit('generateApiCreds');
      this.$axios
        .get(process.env.VUE_APP_API_PATH + 'categories', {
          auth: {
            username: this.$store.state.auth.apiUsername,
            password: this.$store.state.auth.apiPassword,
          }
        })
        .then(resp => {
          this.allCategories = resp.data;
        })
    },

    validateForm()
    {
      this.validation.name                = this.event.name.length > 5 ? true : false;
      this.validation.date                = this.event.date.length > 3 ? true : false;
      this.validation.time                = this.event.time.length > 3 ? true : false;

      // If any values in our validation object are false, then return false
      return Object.values(this.validation).every(Boolean);
    },

    cancel() {
      this.$router.go(-1);
    },

    toggleActive() {
      this.event.active = !this.event.active;
    }

  },

  computed: {

  }

}
</script>

<style scoped>

.btn-dash-submit {
  background-color: #007cc0;
  border-color: #007cc0;
}

.btn-dash-submit:hover {
  background-color: rgb(23, 92, 142);
  border-color: rgb(23, 92, 142);
}

.error {
  border: 2px solid #d9534f;
}

.btn.disabled {
  cursor:not-allowed;
}

.content {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 40px;
  }

}

</style>
